.login-page {
  padding: 0 !important;
  margin: 0 !important;
  background: #ffffff;
  box-shadow: inset 0 0 5rem rgb(0 0 0 / 50%);
  height: 100vh;
  overflow-y: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-form {
  display: flex;
  justify-content: center
}

.login-btn {
  background: #28a745;
  color: #fff;
}