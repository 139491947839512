@font-face {
  font-family: "poppins";
  src: local("Poppins-Medium"),
    url("./components/fonts/Poppins-Medium.ttf") format("truetype");
}

html *, body * {
  font-family: "poppins" !important;
}

body {
  margin: 0;
  padding: 0;
  /* font-family: "sans-serif"; */
  /* SurveyJS Creator V2 */
  --primary: #003da6;
  --primary-light: rgba(26, 183, 250, 0.1);
  --foreground: #000000;
  --primary-foreground: #ffffff;
  --secondary: #1ab7fa;
  /* --background: #555555; */
  /* --background-dim: #4d4d4d;
  --background-dim-light: #4d4d4d; */
}

.sd-progress__bar {
  height: 10px !important;
}

.sd-progress__text {
  display: none !important;
}

.sd-item__control-label {
  margin-left: 10px;
  margin-right: 10px;
}
